@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: #fff;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb {
    background-color: #555;
}